const enum Cashback {
  MAX = 5,
  MIN = 2,
}

const enum Rate {
  MAX = 15,
  MIN = 6,
}

export const calculateSum = (halvaDesyatka: boolean, spending: number, balance: number) => {
  const cashback = halvaDesyatka ? Cashback.MAX : Cashback.MIN
  const rate = halvaDesyatka ? Rate.MAX : Rate.MIN
  const monthSpending = Math.floor((+spending / 100) * cashback)
  const accBalance = Math.floor((+balance / 100) * (rate / 12))

  return Math.min(monthSpending, 5000) + accBalance
}
