import React from "react"

export const HalvaIcon = () => (
  <svg width="64" height="12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.668 8.682a2.836 2.836 0 1 1 0-5.671 2.836 2.836 0 0 1 0 5.671Zm0-8.682a5.849 5.849 0 1 0 4.464 9.607 4.337 4.337 0 0 1-1.592 1.698v.303H24.5V5.824A5.846 5.846 0 0 0 18.677 0h-.009Zm28.063 8.736h-3.849V6.841h3.777c.473 0 1.024.448 1.024.95a.955.955 0 0 1-.952.945Zm-3.849-5.775h3.233a1.006 1.006 0 0 1 .93.941.985.985 0 0 1-1.026.959h-3.136v-1.9Zm6.479 2.536a3.492 3.492 0 0 0 .125-3.615A3.62 3.62 0 0 0 46.393.09h-6.47v11.518h7.238a3.495 3.495 0 0 0 3.537-3.448 3.232 3.232 0 0 0-1.344-2.659l.007-.004ZM36.989 4.341c.972 2.62 1.022 5 1.022 7.271h-2.998c0-1.843-.065-3.521-.448-5.038-.515-2.034-1.478-3.51-2.372-3.51-.894 0-1.859 1.476-2.374 3.51-.388 1.517-.448 3.152-.448 5.038h-3.01c0-2.067.046-4.65 1.018-7.27C28.285 1.894 29.977 0 32.19 0c2.213 0 3.902 1.895 4.807 4.337l-.007.004ZM8.659.096h3.007v1.212a5.823 5.823 0 0 1-2.164 4.543 5.842 5.842 0 0 1 2.164 4.545v1.212H8.66V10.09a2.827 2.827 0 1 0-5.654 0v1.517H0v-1.212A5.837 5.837 0 0 1 2.164 5.85 5.837 5.837 0 0 1 0 1.308V.094h3.006v1.517a2.827 2.827 0 0 0 5.654 0V.094v.002Zm49.005 8.586a2.836 2.836 0 1 1 2.827-2.842 2.834 2.834 0 0 1-2.827 2.836v.006Zm5.824-2.871a5.842 5.842 0 1 0-1.366 3.79 4.353 4.353 0 0 1-1.593 1.698v.302h2.96v-5.79Z"
      fill="#fff"
    />
  </svg>
)
