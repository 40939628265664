// extracted by mini-css-extract-plugin
export var anotherDescr = "newCalculator-module--anotherDescr--x-7iS";
export var block_bottom = "newCalculator-module--block_bottom--KKGwx";
export var block_header = "newCalculator-module--block_header--f9rpB";
export var btn = "newCalculator-module--btn--rnsKq";
export var btsAuto = "newCalculator-module--btsAuto--sxPSj";
export var calcHeader = "newCalculator-module--calcHeader--QfbNm";
export var card = "newCalculator-module--card--LrO8L";
export var cardContainer = "newCalculator-module--cardContainer--zgF8a";
export var cardHead = "newCalculator-module--cardHead--Soq2M";
export var cardHeader = "newCalculator-module--cardHeader--FH9DS";
export var checkbox = "newCalculator-module--checkbox--iWKJz";
export var checkboxContainer = "newCalculator-module--checkboxContainer--Zv1H0";
export var close = "newCalculator-module--close--8DsJ9";
export var content = "newCalculator-module--content--x+3Ef";
export var darkColor = "newCalculator-module--darkColor--R2lSR";
export var descr = "newCalculator-module--descr--IeJpf";
export var halvaIcon = "newCalculator-module--halvaIcon--HWw-Q";
export var head = "newCalculator-module--head--XcKCX";
export var helperText = "newCalculator-module--helperText--sO2c7";
export var hintContainer = "newCalculator-module--hintContainer--jRZep";
export var hintModal = "newCalculator-module--hintModal--kbKhb";
export var hintModalQuestion = "newCalculator-module--hintModalQuestion--f4mKf";
export var link = "newCalculator-module--link--BlLmq";
export var offer = "newCalculator-module--offer--ubRCI";
export var rail = "newCalculator-module--rail--QlNXy";
export var section = "newCalculator-module--section--Kwip2";
export var slider = "newCalculator-module--slider--oSU52";
export var sliderContainer = "newCalculator-module--sliderContainer---LuIJ";
export var sliderDescr = "newCalculator-module--sliderDescr--QZNsQ";
export var sliderSum = "newCalculator-module--sliderSum--PGZsY";
export var specalAnotherDescr = "newCalculator-module--specalAnotherDescr--ZkZnX";
export var sum = "newCalculator-module--sum--8Va6i";
export var thumb = "newCalculator-module--thumb--AWwg+";
export var title = "newCalculator-module--title--PT+OI";
export var title_text = "newCalculator-module--title_text--dChh5";
export var tooltip = "newCalculator-module--tooltip--cp5z1";
export var track = "newCalculator-module--track--Q8ei+";