import React, { ChangeEvent, useEffect, useState } from "react"
import cn from "classnames"
import Container from "@ecom/ui/components/Container"
import Slider from "@material-ui/core/Slider"
import Checkbox from "@material-ui/core/Checkbox"
import Tooltip from "@material-ui/core/Tooltip"
import { Zoom } from "@material-ui/core"
import ClickAwayListener from "@material-ui/core/ClickAwayListener"
import { StaticImage } from "gatsby-plugin-image"
import Button from "@material-ui/core/Button"
import pushToDataLayer from "@ecom/ui/utils/pushToDataLayer"
import { ItemTooltip } from "../ItemTooltip"
import scrollToPersonalForm from "../../helpers/scrollToPersonalForm"
import { pushToDataLayerOnce } from "../../helpers/pushToDataLayerOnce"
import { calculateSum } from "./helpers/calculate"

import { HalvaIcon } from "./images/HalvaIcon"
import { CheckIcon } from "./images/CheckIcon"
import { EmptyIcon } from "./images/EmptyIcon"
import { Question } from "./images/Question"
import question_icon from "../../images/icons/icon_questionV2.svg"
import close_icon from "../../images/icons/icon_closeV2.svg"

import * as styles from "./newCalculator.module.scss"

interface CalculatorProps {
  titleLocationBottom?: boolean
  titleLocationTop?: boolean
  subtitle?: React.ReactNode
  subtext?: React.ReactNode
  darkColor?: boolean
  // TODO:: migrate to new hint
  newHint?: boolean
  anotherDescr?: React.ReactNode
  orderNum?: string
  additionalEventInDataLayer?: boolean
  title?: React.ReactNode
  variant?: "specalAnotherDescr" | "standart" | "inviteFriend" | "btsAuto"
  withoutCheckboxDesytka?: boolean
}

const defSubText =
  "Вы не платите проценты, за вас это сделает магазин. Платеж делится на равные части каждый месяц. К тому же, по подписке «Халва.Десятка», срок рассрочки увеличивается до 10 месяцев у каждого партнера!"

const defSubTitle = "В чем преимущества рассрочки с Халвой?"

const defTitle = <>Рассчитайте выгоду от&nbsp;карты «Халва»</>

export const NewCalculator = ({
  titleLocationBottom = false,
  titleLocationTop = false,
  subtitle = defSubTitle,
  title = defTitle,
  subtext = defSubText,
  darkColor = false,
  newHint = false,
  anotherDescr = "",
  orderNum,
  additionalEventInDataLayer = false,
  variant = "standart",
  withoutCheckboxDesytka = true,
}: CalculatorProps) => {
  const handleClick = () => {
    pushToDataLayer({
      event: "buttonClick",
      name: "cta_calculator",
    })

    scrollToPersonalForm()
  }

  const [spending, setSpending] = useState<number | number[]>(50000)
  const [balance, setBalance] = useState<number | number[]>(35000)
  const [halvaDesyatka, setHalvaDesyatka] = useState(true)
  const [sum, setSum] = useState(0)

  const [open, setOpen] = React.useState(false)

  const calculateSumWithoutCheckbox = () => setSum(calculateSum(true, +spending, +balance))

  const handleTooltipClose = () => {
    setOpen(false)
  }

  const handleTooltipOpen = () => {
    setOpen(true)
  }

  const onChangeSpending = (_: ChangeEvent<{}>, value: number | number[]) => {
    if (additionalEventInDataLayer) {
      pushToDataLayerOnce("monthly_spending_calc", {
        event: "buttonClick",
        name: "monthly_spending_calc",
      })
    }
    setSpending(value)
  }

  const onChangeBalance = (_: ChangeEvent<{}>, value: number | number[]) => {
    if (additionalEventInDataLayer) {
      pushToDataLayerOnce("account_balance_calc", {
        event: "buttonClick",
        name: "account_balance_calc",
      })
    }
    setBalance(value)
  }

  const onChangeCheckbox = () => {
    if (additionalEventInDataLayer) {
      pushToDataLayerOnce("halva_desyatka_check", {
        event: "buttonClick",
        name: "halva_desyatka_check",
      })
    }
    setHalvaDesyatka((prev) => !prev)
  }

  useEffect(() => {
    if (withoutCheckboxDesytka) {
      calculateSumWithoutCheckbox()
    } else {
      setSum(calculateSum(halvaDesyatka, +spending, +balance))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [halvaDesyatka, balance, spending])

  return (
    <section
      className={cn(styles.section, darkColor && styles.darkColor, variant && styles[variant])}
      id="calculator"
      data-exclude={orderNum}
    >
      <Container>
        {titleLocationTop && (
          <div className={styles.block_header}>
            <h3 className={styles.title}>{subtitle}</h3>
            <p className={styles.title_text}>{subtext}</p>
          </div>
        )}
        <div className={styles.calcHeader}>
          <h2 className={styles.head}>{title}</h2>
        </div>
        {anotherDescr ? (
          <p
            className={cn(
              styles.anotherDescr,
              variant === "specalAnotherDescr" && styles.specalAnotherDescr
            )}
          >
            {anotherDescr}
          </p>
        ) : (
          <p className={styles.descr}>
            Все расчеты являются предварительными и могут отличаться от фактически полученных.
          </p>
        )}
        <div className={styles.content}>
          <div className={styles.sliderContainer}>
            <p className={styles.sliderDescr}>Траты за месяц</p>
            <div className={styles.sliderSum}>{spending.toLocaleString().replace(/,/g, " ")} ₽</div>
            <Slider
              value={spending}
              onChange={onChangeSpending}
              max={150000}
              step={1000}
              classes={{
                root: styles.slider,
                track: styles.track,
                thumb: styles.thumb,
                rail: styles.rail,
              }}
            />
            <p className={styles.sliderDescr}>Остаток на счёте</p>
            <div className={styles.sliderSum}>{balance.toLocaleString().replace(/,/g, " ")} ₽</div>
            <Slider
              value={balance}
              onChange={onChangeBalance}
              max={400000}
              step={1000}
              classes={{
                root: styles.slider,
                track: styles.track,
                thumb: styles.thumb,
                rail: styles.rail,
              }}
            />
            {/* TODO:: migratge to new hint */}
            {!withoutCheckboxDesytka && newHint ? (
              <ItemTooltip
                icon={question_icon}
                className={{ container: styles.checkboxContainer }}
                hint="Подписка «Халва.Десятка» дает дополнительные преимущества вашей карте. Увеличенная рассрочка до 10 месяцев, повышенный кэшбэк до 10% и до 17% на остаток. 
                Первый месяц подписки — 0 рублей, далее — 399 рублей в мес. или 3799 руб. в год (скидка -20%)."
              >
                <Checkbox
                  classes={{ root: styles.checkbox }}
                  checked={halvaDesyatka}
                  onChange={onChangeCheckbox}
                  color="primary"
                  checkedIcon={<CheckIcon />}
                  icon={<EmptyIcon />}
                />
                <p className={styles.helperText}>
                  С подпиской <span>«Халва.Десятка»</span>
                </p>
              </ItemTooltip>
            ) : (
              !withoutCheckboxDesytka && (
                <div className={styles.checkboxContainer}>
                  <Checkbox
                    classes={{ root: styles.checkbox }}
                    checked={halvaDesyatka}
                    onChange={onChangeCheckbox}
                    color="primary"
                    checkedIcon={<CheckIcon />}
                    icon={<EmptyIcon />}
                  />
                  <p className={styles.helperText}>
                    С подпиской <span>«Халва.Десятка»</span>
                  </p>
                  <ClickAwayListener onClickAway={handleTooltipClose}>
                    <div>
                      <Tooltip
                        classes={{
                          tooltip: styles.tooltip,
                        }}
                        PopperProps={{
                          disablePortal: false,
                        }}
                        onClose={handleTooltipOpen}
                        open={open}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        title={
                          <>
                            <img
                              src={question_icon}
                              alt="question"
                              className={styles.hintModalQuestion}
                            />
                            <div className={styles.hintModal}>
                              Подписка «Халва.Десятка» дает дополнительные преимущества вашей карте.
                              Увеличенная рассрочка от 10 месяцев, повышенный кэшбэк до 10% <br /> и
                              до 17% на остаток. <br />
                              Первый месяц подписки —&nbsp;0&nbsp;рублей, далее
                              —&nbsp;399&nbsp;рублей в мес. или&nbsp;3799&nbsp;руб. в год (скидка
                              -20%). Стоимость годовой подписки можно{" "}
                              <a
                                className={styles.link}
                                href="https://halvacard.ru/halvadesyatka/"
                                target="_blank"
                                rel="noreferrer"
                              >
                                вернуть
                              </a>
                              .
                            </div>
                            <div
                              className={styles.close}
                              onClick={handleTooltipClose}
                              aria-hidden="true"
                            >
                              <img src={close_icon} alt="close" />
                            </div>
                          </>
                        }
                        TransitionComponent={Zoom}
                      >
                        <div
                          className={styles.hintContainer}
                          onClick={handleTooltipOpen}
                          aria-hidden="true"
                        >
                          <Question />
                        </div>
                      </Tooltip>
                    </div>
                  </ClickAwayListener>
                </div>
              )
            )}
          </div>
          <div className={styles.card} id="calculatorCard">
            <StaticImage
              alt="card"
              src="./images/card.png"
              placeholder="blurred"
              formats={["auto", "webp", "avif"]}
            />
            <div className={styles.cardContainer}>
              <div className={styles.cardHeader}>
                <p className={styles.cardHead}>Ваша прибыль</p>
                <div className={styles.halvaIcon}>
                  <HalvaIcon />
                </div>
              </div>
              <div className={styles.sum}>
                {sum.toLocaleString().replace(/,/g, " ")} <span>₽</span>
              </div>
              <Button onClick={handleClick} classes={{ root: styles.btn }}>
                Оформить карту
              </Button>
            </div>
          </div>
        </div>
        {titleLocationBottom && (
          <div className={styles.block_bottom} id="textBottomCalculator">
            <h3 className={styles.title}>{subtitle}</h3>
            <p className={styles.title_text}>{subtext}</p>
          </div>
        )}
      </Container>
    </section>
  )
}
