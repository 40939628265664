import React from "react"

export const CheckIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="1"
      y="1"
      width="22"
      height="22"
      rx="5"
      fill="white"
      stroke="#FF4E50"
      strokeWidth="2"
    />
    <rect x="5" y="5" width="14" height="14" rx="3" fill="#FF4E50" />
    <path d="M7.99991 11.5982L11.2777 14.876L16.8759 9.27776" stroke="white" />
  </svg>
)
